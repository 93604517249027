import { Routes, Route } from 'react-router-dom';
import Home from './routes/Home';
import Power from './routes/Power/Power';
import PowerLevel1 from './routes/Power/Power-level1';
import PowerLevel2 from './routes/Power/Power-level2';
import Motor from './routes/Motor/Motor';
import MotorHelmsman from './routes/Motor/Motor-helmsman';
import MotorDaySkipper from './routes/Motor/Motor-day-skipper';
import MotorCoastalSkipper from './routes/Motor/Motor-coastal-skipper';
import MotorYachtmaster from './routes/Motor/Motor-yachtmaster';
import Sail from './routes/Sail/Sail';
import SailStartYachting from './routes/Sail/Sail-start-yachting';
import SailCompetentCrew from './routes/Sail/Sail-competent-crew';
import SailDaySkipper from './routes/Sail/Sail-day-skipper';
import SailCoastalSkipper from './routes/Sail/Sail-coastal-skipper';
import SailYachtmaster from './routes/Sail/Sail-yachtmaster';
import Shorebased from './routes/Shorebased/Shorebased';
import ShorebasedNavigation from './routes/Shorebased/Shorebased-navigation';
import ShorebasedDaySkipperTheory from './routes/Shorebased/Shorebased-day-skipper-theory';
import ShorebasedCoastalSkipperTheory from './routes/Shorebased/Shorebased-coastal-skipper-theory';
import ShorebasedYachtmasterOceanTheory from './routes/Shorebased/Shorebased-yachtmaster-ocean-theory';
import ShorebasedBasicSeaSurvival from './routes/Shorebased/Shorebased-basic-sea-survival';
import ShorebasedDieselEngine from './routes/Shorebased/Shorebased-diesel-engine';
import ShorebasedMarineRadioShortRange from './routes/Shorebased/Shorebased-marine-radio-short-range';
import ShorebasedRadar from './routes/Shorebased/Shorebased-radar';
import ShorebasedFirstAid from './routes/Shorebased/Shorebased-first-aid';
import ShorebasedProfessionalPractices from './routes/Shorebased/Shorebased-professional-practices';
import OwnBoat from './routes/OwnBoat';
import Vouchers from './routes/Vouchers';
import PowerBoatCharter from './routes/BoatCharter/PowerBoatCharter';
import SailBoatCharter from './routes/BoatCharter/SailBoatCharter';
import About from './routes/About';
import Privacy from './routes/Privacy';
import Tcs from './routes/Tcs';
import Booking from './routes/Booking';
import Contact from './routes/Contact';
import Layout from './components/Layout';

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="power" element={<Power />} />
          <Route path="powerboat-level-1" element={<PowerLevel1 />} />
          <Route path="powerboat-level-2" element={<PowerLevel2 />} />
          <Route path="motor" element={<Motor />} />
          <Route path="motorboat-helmsman" element={<MotorHelmsman />} />
          <Route path="motorboat-day-skipper" element={<MotorDaySkipper />} />
          <Route path="motorboat-coastal-skipper" element={<MotorCoastalSkipper />} />
          <Route path="motorboat-yachtmaster" element={<MotorYachtmaster />} />
          <Route path="sail" element={<Sail />} />
          <Route path="sailboat-start-yachting" element={<SailStartYachting />} />
          <Route path="sailboat-competent-crew" element={<SailCompetentCrew />} />
          <Route path="sailboat-day-skipper" element={<SailDaySkipper />} />
          <Route path="sailboat-coastal-skipper" element={<SailCoastalSkipper />} />
          <Route path="sailboat-yachtmaster" element={<SailYachtmaster />} />
          <Route path="shorebased" element={<Shorebased />} />
          <Route path="shorebased-navigation" element={<ShorebasedNavigation />} />
          <Route path="shorebased-day-skipper-theory" element={<ShorebasedDaySkipperTheory />} />
          <Route path="shorebased-coastal-skipper-theory" element={<ShorebasedCoastalSkipperTheory />} />
          <Route path="shorebased-yachtmaster-ocean-theory" element={<ShorebasedYachtmasterOceanTheory />} />
          <Route path="shorebased-basic-sea-survival" element={<ShorebasedBasicSeaSurvival />} />
          <Route path="shorebased-diesel-engine" element={<ShorebasedDieselEngine />} />
          <Route path="shorebased-marine-radio-short-range" element={<ShorebasedMarineRadioShortRange />} />
          <Route path="shorebased-radar" element={<ShorebasedRadar />} />
          <Route path="shorebased-first-aid" element={<ShorebasedFirstAid />} />
          <Route path="shorebased-professional-practices" element={<ShorebasedProfessionalPractices />} />
          <Route path="own-boat" element={<OwnBoat />} />
          <Route path="vouchers" element={<Vouchers />} />
          <Route path="powerboat-charter" element={<PowerBoatCharter />} />
          <Route path="sailboat-charter" element={<SailBoatCharter />} />
          <Route path="about" element={<About />} />
          <Route path="terms-and-conditions" element={<Tcs />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="booking" element={<Booking />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<p>Not found!</p>} />
        </Route>
      </Routes>
    </>
  );
};

export default App;