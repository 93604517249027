import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { List } from 'react-bootstrap-icons';

function NavBarWithScroll() {
  return (
    <Navbar className='newNav' expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">Home</Navbar.Brand>
        <Nav.Link href="/booking" id='navbarLink'>Booking Form</Nav.Link>
        <Navbar.Toggle aria-controls="navbarScroll">
          <List color='white'/>
        </Navbar.Toggle>
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '200px' }}
            navbarScroll
          >
            <NavDropdown title="RYA Courses" id="navbarScrollingDropdown">
              <NavDropdown title="Powerboating" id="navbarScrollingDropdown-sub">
                <NavDropdown.Item href="/power">Overview</NavDropdown.Item>
                <NavDropdown.Item href="/powerboat-level-1">Powerboat Level 1</NavDropdown.Item>
                <NavDropdown.Item href="/powerboat-level-2">Powerboat Level 2</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Motorboating" id="navbarScrollingDropdown-sub">
                <NavDropdown.Item href="/motor">Overview</NavDropdown.Item>
                <NavDropdown.Item href="/motorboat-helmsman">RYA Helmsman</NavDropdown.Item>
                <NavDropdown.Item href="/motorboat-day-skipper">RYA Day Skipper</NavDropdown.Item>
                <NavDropdown.Item href="/motorboat-coastal-skipper">RYA Coastal Skipper</NavDropdown.Item>
                <NavDropdown.Item href="/motorboat-yachtmaster">RYA Yachtmaster</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Sailing" id="navbarScrollingDropdown-sub">
                <NavDropdown.Item href="/sail">Overview</NavDropdown.Item>
                <NavDropdown.Item href="/sailboat-start-yachting">RYA Start Yachting</NavDropdown.Item>
                <NavDropdown.Item href="/sailboat-competent-crew">RYA Competent Crew</NavDropdown.Item>
                <NavDropdown.Item href="/sailboat-day-skipper">RYA Day Skipper</NavDropdown.Item>
                <NavDropdown.Item href="/sailboat-coastal-skipper">RYA Coastal Skipper</NavDropdown.Item>
                <NavDropdown.Item href="/sailboat-yachtmaster">RYA Yachtmaster</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Shorebased" id="navbarScrollingDropdown-sub">
                <NavDropdown.Item href="/shorebased">Overview</NavDropdown.Item>
                <NavDropdown title="Navigation" id="navbarScrollingDropdown-sub">
                  <NavDropdown.Item href="/shorebased-navigation">RYA Essential Navigation and Seamanship</NavDropdown.Item>
                  <NavDropdown.Item href="/shorebased-day-skipper-theory">RYA Day Skipper Theory</NavDropdown.Item>
                  <NavDropdown.Item href="/shorebased-coastal-skipper-theory">RYA Coastal Skipper/ Yachtmaster Offshore Theory</NavDropdown.Item>
                  <NavDropdown.Item href="/shorebased-yachtmaster-ocean-theory">RYA Yachtmaster Ocean Theory</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Specialist" id="navbarScrollingDropdown-sub">
                  <NavDropdown.Item href="/shorebased-basic-sea-survival">RYA Basic Sea Survival</NavDropdown.Item>
                  <NavDropdown.Item href="/shorebased-diesel-engine">RYA Diesel Engine</NavDropdown.Item>
                  <NavDropdown.Item href="/shorebased-marine-radio-short-range">RYA Marine Radio Short Range</NavDropdown.Item>
                  <NavDropdown.Item href="/shorebased-radar">RYA Radar</NavDropdown.Item>
                  <NavDropdown.Item href="/shorebased-first-aid">RYA First Aid</NavDropdown.Item>
                  <NavDropdown.Item href="/shorebased-professional-practices">RYA Professional Practices & Responsibilities</NavDropdown.Item>
                </NavDropdown>
              </NavDropdown>
            </NavDropdown>
            <Nav.Link href="/own-boat" id='navbarLink'>Own Boat & Bespoke Courses</Nav.Link>
            <Nav.Link href="/powerboat-charter" id='navbarLink'>Powerboat Charter</Nav.Link>
            {/* <NavDropdown title="Boat Charter" id="navbarScrollingDropdown">
              <NavDropdown.Item href="/powerboat-charter">Powerboat Charter</NavDropdown.Item>
              <NavDropdown.Item href="/sailboat-charter">Sailboat Charter</NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link href="/vouchers" id='navbarLink'>Gift Vouchers</Nav.Link>
            <NavDropdown title="More Information" id="navbarScrollingDropdown">
              <NavDropdown.Item href="/about">About Us</NavDropdown.Item>
              <NavDropdown.Item href="/terms-and-conditions">Terms & Conditions</NavDropdown.Item>
              <NavDropdown.Item href="/privacy">Privacy Policy</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/contact" id='navbarLink'>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarWithScroll;