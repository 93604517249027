import * as React from "react";
import Box from "@mui/material/Box";
import { useForm, ValidationError } from "@formspree/react";
import 'react-phone-number-input/style.css'
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

const Booking = () => {
  const powerboatoptions = [
    { key: "powerboat-taster", label: "Taster" },
    { key: "powerboat-level-1", label: "Powerboat Level 1" },
    { key: "powerboat-level-2", label: "Powerboat Level 2" },
    { key: "powerboat-bespoke", label: "Powerboat Bespoke" },
    { key: "powerboat-fast-track", label: "Powerboat Fast Track" },
  ];

  const motoroptions = [
    { key: "motorboat-taster", label: "Taster" },
    { key: "motorboat-rya-helmsman", label: "RYA Helmsman" },
    { key: "motorboat-rya-dayskipper", label: "RYA Day Skipper" },
    { key: "motorboat-rya-coastalskipper", label: "RYA Coastal Skipper" },
    { key: "motorboat-rya-yachtmaster", label: "RYA Yachtmaster" },
    { key: "motorboat-bespoke", label: "Bespoke" },
    { key: "motorboat-fast-track", label: "Fast Track" },
  ];

  const sailoptions = [
    { key: "sailboat-taster", label: "Taster" },
    { key: "sailboat-rya-startyachting", label: "RYA Start Yachting" },
    { key: "sailboat-rya-competentcrew", label: "RYA Competent Crew" },
    { key: "sailboat-rya-helmsman", label: "RYA Helmsman" },
    { key: "sailboat-rya-dayskipper", label: "RYA Day Skipper" },
    { key: "sailboat-rya-coastalskipper", label: "RYA Coastal Skipper" },
    { key: "sailboat-rya-yachtmaster", label: "RYA Yachtmaster" },
    { key: "sailboat-bespoke", label: "Bespoke" },
    { key: "sailboat-fast-track", label: "Fast Track" },
  ];

  const shorebasednavigationoptions = [
    { key: "shorebased-navigation", label: "RYA Essential Navigation and Seamanship" },
    { key: "shorebased-dayskipper-theory", label: "RYA Day Skipper Theory" },
    { key: "shorebased-coastalskipper-theory", label: "RYA Coastal Skipper Theory" },
    { key: "shorebased-yachtmaster-theory", label: "RYA Yachtmaster Theory" },
  ];

  const shorebasedspecialistoptions = [
    { key: "shorebased-basic-sea-survival", label: "RYA Basic Sea Survival" },
    { key: "shorebased-diesel-engine", label: "RYA Diesel Engine" },
    { key: "shorebased-marine-radio-shortrange", label: "RYA Marine Radio Short Range" },
    { key: "shorebased-first-aid", label: "RYA First Aid" },
    { key: "shorebased-radar", label: "RYA Radar" },
    { key: "shorebased-professional-practices", label: "RYA Professional Practices and Responsibilities" },
  ];


  const [state, handleSubmit] = useForm("xknardbv");
  if (state.succeeded) {
    return <p>Thanks for submitting your booking form!</p>;
  }
  return (
    <div className="booking-content">
      <Box sx={{ flexGrow: 1 }}>
        {/* <Grid container spacing={6}> */}
        <div className="form-row">
          <h1>Booking Form</h1>
          <form onSubmit={handleSubmit}>
            <h2>Section A</h2>
            <p>
              Please complete the following form to book your place on a
              course only when asked to do so by Portugal Sail and Power.
            </p>

            <label htmlFor="title">Title</label>
            <select id="title" name="title"
              required
            >
              <option value="none" selected disabled hidden>
                Select an Option (Required)
              </option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Ms">Ms</option>
              <option value="Dr">Dr</option>
              <option value="Prof">Prof</option>
            </select>
            {/* full name */}
            <input
              id="fullname"
              type="text"
              name="full name"
              placeholder="Full Name"
              required
            />
            <ValidationError
              prefix="Full Name"
              field="full name"
              errors={state.errors}
            />

            {/* address line 1*/}
            <input
              id="address line 1"
              type="text"
              name="address-line-1"
              placeholder="Address Line 1"
              required
            />
            <ValidationError
              prefix="Address Line 1"
              field="address-line-1"
              errors={state.errors}
            />

            {/* address line 2 */}
            <input
              id="address line 2"
              type="text"
              name="address-line-2"
              placeholder="Address Line 2"
            />
            <ValidationError
              prefix="Address Line 2"
              field="address-line-2"
              errors={state.errors}
            />

            {/* city */}
            <input
              id="city"
              type="text"
              name="city"
              placeholder="City"
              required
            />
            <ValidationError prefix="City" field="city" errors={state.errors} />

            {/* postcode */}
            <input
              id="postcode"
              type="text"
              name="postcode"
              placeholder="Postcode"
              required
            />
            <ValidationError
              prefix="Postcode"
              field="postcode"
              errors={state.errors}
            />

            {/* country */}
            <input
              id="country"
              type="text"
              name="country"
              placeholder="Country"
              required
            />
            <ValidationError
              prefix="Country"
              field="country"
              errors={state.errors}
            />

            <input
              id="mainphone"
              type="tel"
              name="mainphone"
              placeholder="Phone Number (Required)"
              required
            />

            {/* email */}
            <input
              id="email"
              type="email"
              name="email"
              placeholder="Email Address"
              required
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />

            {/* date of birth */}
            {/* client was not happy with format so going for 
            regular text input, not date input */}
            <label htmlFor="dob">Date of Birth (dd/mm/yyyy)</label>
            <input
              id="dob"
              // type="date"
              type="text"
              name="dob"
              placeholder="Date of Birth"
              required
            />
            <ValidationError
              prefix="Date of Birth"
              field="dob"
              errors={state.errors}
            />
            <label htmlFor="how-contact">
              How would you like to be contacted?
            </label>
            <select id="how-contact" name="how-contact"
              required
            >
              <option value="none" selected disabled hidden>
                Select an Option (Required)
              </option>
              <option value="Email">Email</option>
              <option value="Phone Call">Phone Call</option>
              <option value="Text">Text</option>
              <option value="WhatsApp Text">WhatsApp Text</option>
              <option value="WhatsApp Audio Call">WhatsApp Audio Call</option>
            </select>
            <ValidationError
              prefix="How would you like to be contacted?"
              field="how-contact"
              errors={state.errors}
            />

            {/* how did you hear about us? */}
            <label htmlFor="how-hear">
              How did you hear about us? (Optional)
            </label>
            <select id="how-hear" name="how-hear">
              <option value="none" selected disabled hidden>
                Select an Option (Optional)
              </option>
              <option value="Google">Google</option>
              <option value="Facebook">Facebook</option>
              <option value="Word of Mouth">Word of Mouth</option>
              <option value="Other">Other</option>
            </select>

            {/* how did you hear about us? */}
            <label htmlFor="how-hear-other">
              If other, please specify (Optional)
            </label>
            <input
              id="how-hear-other"
              type="text"
              name="how-hear-other"
              placeholder="Other"
            />
            <ValidationError
              prefix="How did you hear about us?"
              field="how-hear-other"
              errors={state.errors}
            />

            <h3>Course Details</h3>
            <p>
              Please select all the courses that you are undertaking.
            </p>

            {/* Sail Options */}
            <label htmlFor="sail-options">
              Select Sail Courses (Optional)
            </label>
            <DropdownMultiselect
              className="dropdown-multi-select"
              options={sailoptions}
              name="sail-options"
            />

            {/* Powerboat Options */}
            <label htmlFor="powerboat-options">
              Select Powerboat Courses (Optional)
            </label>
            <DropdownMultiselect
              className="dropdown-multi-select"
              options={powerboatoptions}
              name="power-options"
            />
            {/* Motor Options */}
            <label htmlFor="motor-options">
              Select Motor Courses (Optional)
            </label>
            <DropdownMultiselect
              className="dropdown-multi-select"
              options={motoroptions}
              name="motor-options"
            />

            {/* Shorebased Navigation */}
            <label htmlFor="shorebased-navigation">
              Select Shorebased Navigation Courses (Optional)
            </label>
            <DropdownMultiselect
              className="dropdown-multi-select"
              options={shorebasednavigationoptions}
              name="shorebased-navigation"
            />

            {/* Shorebased Specialist */}
            <label htmlFor="shorebased-specialist">
              Select Shorebased Specialist Courses (Optional)
            </label>
            <DropdownMultiselect
              className="dropdown-multi-select"
              options={shorebasedspecialistoptions}
              name="shorebased-specialist"
            />

            {/* Agreed course dates */}
            <label htmlFor="agreed-course-date">
              Agreed Course Date:
            </label>
            <input
              id="agreed-course-date"
              type="date"
              name="agreed-course-date"
              placeholder="Agreed Course Date"
            />

            {/* Relevant experience */}
            <label htmlFor="relevant-experience">Relevant Experience:</label>
            <textarea
              id="relevant-experience"
              name="relevant-experience"
              placeholder="Please list any relevant experience you have (if any)."
            ></textarea>

            {/* RYA qualifications */}
            <label htmlFor="rya-qualifications">RYA Qualifications:</label>
            <textarea
              id="rya-qualifications"
              name="rya-qualifications"
              placeholder="Please list any RYA qualifications you have (if any)."
            ></textarea>

            <h2>Section B</h2>
            <h3>Billing Details</h3>
            <p>
              If your billing details are different from your personal details,
              please fill in the form below. If not please feel free to leave blank.
            </p>
            {/* TODO: stop scroll up on click */}
            {/* <button onClick={revealBilling}>If Billing Details Are Different From Personal Details Click Here</button> */}
            <div id="billing-details" 
            // style={{display: "none"}}
            >
              <input
                id="billing-name"
                type="text"
                name="billing-name"
                placeholder="(Company) Name"
              />
              <input
                id="billing-address-line-1"
                type="text"
                name="billing-address-line-1"
                placeholder="Address Line 1"
              />
              <input
                id="billing-address-line-2"
                type="text"
                name="billing-address-line-2"
                placeholder="Address Line 2"
              />
              <input
                id="billing-city"
                type="text"
                name="billing-city"
                placeholder="City"
              />
              <input
                id="billing-postcode"
                type="text"
                name="billing-postcode"
                placeholder="Postcode"
              />
              <input
                id="billing-country"
                type="text"
                name="billing-country"
                placeholder="Country"
              />

              <input
                id="NIF-number"
                type="text"
                name="NIF-number"
                placeholder="NIF Number"
              />
              <input
                id="IVA-number"
                type="text"
                name="IVA-number"
                placeholder="IVA Number"
              />
              <input id="billing-email" type="email" name="billing-email" placeholder="Email Address" />
            </div>

            <h2>Section C</h2>
            <h3>Health</h3>
            {/* Declarations */}
            <p>
              <b>I declare</b> to the best of my knowledge I am not suffering
              from a medical disability such as Angina, Asthma, Diabetes,
              Epilepsy, giddy spells, or any other heart condition, and am fit
              to participate in the course.
            </p>

            <div class="form-group">
              <input type="checkbox" id="health" name="health" />
              <label for="health">
                Please tick if you agree, otherwise please give details below.
              </label>
            </div>
            <p>
              The above listed illnesses do not necessarily preclude you from
              taking part, but we must have the relevant details. Please detail
              level of severity and medication received.
            </p>

            {/* Medical Conditions */}
            <label htmlFor="medical-conditions">Medical Conditions:</label>
            <textarea
              id="medical-conditions"
              name="medical-conditions"
              placeholder="Please list any medical conditions you have (if any)."
            ></textarea>

            {/* Swim yes/ no */}
            <label htmlFor="swim">Can you swim 50 meters with clothing?</label>
            <select id="swim" name="swim" 
            required
            >
              <option value="none" selected disabled hidden>
                Select an Option (Optional)
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>

            {/* Special Dietary Needs? */}
            <textarea
              id="special-dietary-needs"
              name="special-dietary-needs"
              placeholder="Do you have any special dietary needs? (if any)."
            ></textarea>

            {/* emergency contact */}
            <label htmlFor="emergency-contact">Emergency Contact:</label>
            <input
              id="emergency-contact"
              type="text"
              name="emergency-contact"
              placeholder="Contact Name"
            />

            {/* emergency contact number */}
            <input
              id="emergency-contact-number"
              type="text"
              name="emergency-contact-number"
              placeholder="Emergency Contact Number"
              required
            />

            {/* Doctor's details */}
            <label htmlFor="doctor-details">Doctor's Details:</label>
            <input
              id="doctor-name"
              type="text"
              name="doctor-name"
              placeholder="Doctor's Name"
            />

            <input
              id="doctor-number"
              type="text"
              name="doctor-number"
              placeholder="Doctor's Number"
            />

            <h2>Section D</h2>
            <p>
              You are strongly recommended to arrange your own Adventure
              Activity Insurance cover, for cancellation, injury, personal
              belongings and personal liability.
            </p>

            {/* own insurance */}
            <label htmlFor="own-insurance">
              Do you have your own insurance?
            </label>
            <select id="own-insurance" name="own-insurance" 
            required
            >
              <option value="none" selected disabled hidden>
                Select an Option (Required)
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>

            <p>
              Before booking any of our courses, please read and understand our{" "}
              <a href="/terms-and-conditions">Terms & Conditions</a>
            </p>

            <p>
              By clicking the Agree & Submit button you are confirming that you
              have read and accepted our Terms & Conditions.
            </p>

            <button type="submit" disabled={state.submitting}>
              Agree & Submit Form
            </button>
          </form>
        </div>
      </Box>
    </div>
  );
}

export default Booking;
